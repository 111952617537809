import {
  getDemoData,
  getPreregistration
} from "./chunk-GXGNRLHF.js";
import {
  getMember
} from "./chunk-CLB3IAI5.js";
import {
  axios_default
} from "./chunk-57FE3PQR.js";
import {
  useGlobalContext
} from "./chunk-PUFAVMO2.js";
import {
  require_dist
} from "./chunk-3AFPT354.js";
import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  __toESM
} from "./chunk-NFP6CRDO.js";

// src/providers/user.tsx
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
var import_react_secure_storage = __toESM(require_dist());
import { AUTHORIZATION_KEY, Storage } from "aesirx-lib";
var userContext = createContext({
  userLoading: void 0,
  getData: void 0
});
var UserContextProvider = ({ children }) => {
  const { jwt, onLogout } = useGlobalContext();
  const accessToken = Storage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN);
  const [preregistration, setPreregistration] = useState(null);
  const [aesirxData, setAesirxData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (jwt && accessToken) {
      try {
        (async () => {
          await getData(jwt, accessToken);
        })();
      } catch (error) {
        notify(error.message, "error");
      }
    }
  }, [jwt, accessToken]);
  const getData = useCallback(async (jwt2, accessToken2) => {
    setLoading(true);
    let _preregistration = {};
    let aesirxData2 = {};
    if (jwt2) {
      try {
        const member = await getMember(accessToken2);
        aesirxData2 = { ...member };
        const preregistrationData = (await getPreregistration(jwt2)).data?.objForm;
        const demo = (await getDemoData(jwt2)).data?.objForm;
        if (preregistrationData?.aesirXAccount) {
          const response = await axios_default.post("/api/member/checkadmin", {
            username: preregistrationData?.aesirXAccount
          });
          response?.data && setIsAdmin(true);
        }
        _preregistration = {
          ...preregistrationData,
          ..._preregistration,
          ...{ demo }
        };
        import_react_secure_storage.default.setItem("auth", true);
        import_react_secure_storage.default.setItem("access_token", String(accessToken2));
        import_react_secure_storage.default.setItem("member_id", String(aesirxData2?.member_id));
        import_react_secure_storage.default.setItem("member_email", String(aesirxData2?.email));
      } catch (error) {
        if (error?.response?.status === 403 || error?.response?.status === 401 && error?.response?.data?.error !== "Account preregistration doesn't exist") {
          onLogout();
        }
      }
    }
    setAesirxData(aesirxData2);
    setPreregistration(_preregistration);
    setLoading(false);
  }, []);
  return /* @__PURE__ */ React.createElement(
    userContext.Provider,
    {
      value: {
        preregistration,
        aesirxData,
        isAdmin,
        userLoading: loading,
        getData
      }
    },
    children
  );
};
var useUserContext = () => useContext(userContext);

export {
  UserContextProvider,
  useUserContext
};
