import {
  AssetsItemModel,
  AssetsModel,
  ColectionModel,
  CollectionItemModel
} from "./chunk-EUJ45HM6.js";
import {
  DamRoute_default
} from "./chunk-KUY3N2B7.js";

// src/Dam/Dam.ts
import axios from "axios";
var AesirxDamApiService = class {
  constructor() {
    this.route = null;
    this.search = async (data) => {
      try {
        const dataSearchAssets = await this.route.searchAssets(data);
        const dataSearchCollections = await this.route.searchCollections(data);
        let resultsAssets = null;
        let resultCollection = null;
        if (dataSearchAssets) {
          resultsAssets = new AssetsModel(dataSearchAssets);
        }
        if (dataSearchCollections) {
          resultCollection = new ColectionModel(dataSearchCollections);
        }
        if (resultsAssets) {
          resultsAssets = resultsAssets.toJSON();
        }
        if (resultCollection) {
          resultCollection = resultCollection.toJSON();
        }
        return {
          assets: resultsAssets ?? [],
          collections: resultCollection ?? []
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getAsset = async (id) => {
      try {
        const data = await this.route.getAsset(id);
        let results = null;
        if (data) {
          results = new AssetsItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          item: results ?? {}
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getAssets = async (collectionId = 0, dataFilter) => {
      try {
        const data = await this.route.getAssets(collectionId, dataFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new AssetsModel(data);
          pagination = results.getPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results ?? [],
          pagination: pagination ?? {}
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.createAssets = async (data) => {
      try {
        const dataToSubmit = AssetsItemModel.__transformItemToApiOfCreation(data);
        const result = await this.route.createAssets(dataToSubmit);
        if (result.result) {
          let data2 = new AssetsModel(result.result);
          if (data2) {
            data2 = data2.toJSON();
            return data2;
          }
        }
        return { message: "Something have qwe" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.updateAssets = async (data) => {
      try {
        const dataToSubmit = AssetsItemModel.__transformItemToApiOfUpdation(data);
        const result = await this.route.updateAssets(dataToSubmit);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.deleteAssets = async (ids) => {
      try {
        const dataToSubmit = AssetsItemModel.__transformItemToApiOfDelete(ids);
        const result = await this.route.deleteAssets(dataToSubmit);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getCollection = async (id) => {
      try {
        const data = await this.route.getCollection(id);
        let results = null;
        if (data) {
          results = new CollectionItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          item: results ?? {}
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getCollections = async (collectionId = 0, dataFilter) => {
      try {
        const data = await this.route.getCollections(collectionId, dataFilter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new ColectionModel(data);
          pagination = results.getPagination();
        }
        if (results) {
          results = results.toJSON();
        }
        return {
          list: results ?? [],
          pagination: pagination ?? {}
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.createCollections = async (data) => {
      try {
        const dataToSubmit = CollectionItemModel.__transformItemToApiOfCreation(data);
        const result = await this.route.createCollections(dataToSubmit);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.updateCollections = async (data) => {
      try {
        const dataToSubmit = CollectionItemModel.__transformItemToApiOfUpdation(data);
        const result = await this.route.updateCollections(dataToSubmit);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.deleteCollections = async (ids) => {
      try {
        const dataToSubmit = CollectionItemModel.__transformItemToApiOfDelete(ids);
        const result = await this.route.deleteCollections(dataToSubmit);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDamSubscription = async () => {
      try {
        const data = await this.route.getSubscription();
        let result = null;
        if (data.result) {
          result = data.result;
        }
        return result;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.updateDamSubscription = async (data) => {
      try {
        const result = await this.route.updateDamSubscription(data);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.moveToFolder = async (data) => {
      try {
        const dataToSubmit = CollectionItemModel.__transformItemToApiOfMoveToFolder(data);
        const result = await this.route.moveToFolder(dataToSubmit);
        if (result.result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.downloadCollections = async (ids) => {
      try {
        const dataToSubmit = CollectionItemModel.__transformItemToApiOfDownload(ids);
        const result = await this.route.downloadCollections(dataToSubmit);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new DamRoute_default();
  }
};

export {
  AesirxDamApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
