import {
  ModalDAMComponent
} from "./chunk-25MFLZ6O.js";
import {
  SVGComponent
} from "./chunk-C5UZGLWY.js";

// src/components/Form/FormEditor/index.tsx
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useState } from "react";

// esbuild-scss-modules-plugin:styles.module.scss
var digest = "2a79fd8ef75e98dd9c9aa035e8a553dc6de602a6885f1310c1ffbbbb9bf6ca38";
var classes = { "image-upload-button": "_image-upload-button_m68xg_1", "imageUploadButton": "_image-upload-button_m68xg_1", "custom-editor": "_custom-editor_m68xg_6", "customEditor": "_custom-editor_m68xg_6" };
var css = `._image-upload-button_m68xg_1 {
  width: 30px;
  height: 30px;
}

._custom-editor_m68xg_6 {
  --ck-custom-background: transparent;
  --ck-custom-border: var(--aesirxui-border-color);
  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-text: var(--aesirxui-body-color);
  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: var(--aesirxui-body-bg);
  --ck-color-button-default-active-background: var(--aesirxui-gray-500);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);
  --ck-color-button-on-background: var(--aesirxui-green-light);
  --ck-color-button-on-hover-background: var(--aesirxui-gray-500);
  --ck-color-button-on-active-background: var(--aesirxui-gray-500);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);
  --ck-color-button-on-color: var(--aesirxui-success);
  --ck-color-toolbar-background: transparent;
  --ck-color-base-background: var(--aesirxui-body-bg);
  --ck-color-toolbar-border: var(--aesirxui-border-color);
  --ck-color-base-border: var(--aesirxui-border-color);
  --ck-color-list-background: var(--aesirxui-white);
  --ck-color-list-button-on-background: var(--aesirxui-white);
  --ck-color-list-button-on-text: var(--aesirxui-success);
  --ck-color-list-button-hover-text: var(--aesirxui-success);
  --ck-color-list-button-hover-background: var(--aesirxui-green-light);
  --ck-color-list-button-on-background-focus: var(--aesirxui-green-light);
  --ck-color-dropdown-panel-background: var(--aesirxui-white);
  --ck-color-dropdown-panel-border: var(--aesirxui-border-color);
  --ck-color-image-caption-background: transparent;
  --ck-color-image-caption-text: var(--aesirxui-body-color);
  --ck-color-widget-editable-focus-background: transparent;
  --ck-color-input-background: var(--aesirxui-white);
  --ck-color-input-border: var(--aesirxui-border-color);
  --ck-color-input-text: var(--aesirxui-body-color);
  --ck-color-split-button-hover-background: var(--aesirxui-green-light);
  --ck-color-split-button-hover-border: var(--aesirxui-border-color);
}`;
(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement("style");
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();
var styles_module_default = classes;

// src/components/Form/FormEditor/index.scss
var css2 = `.ck .ck-content pre {
  color: var(--body-color);
}
.ck .ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  color: var(--bs-success);
}
.ck .ck-source-editing-area > textarea {
  background: transparent;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css2));

// src/components/Form/FormEditor/index.tsx
var FormEditor = ({ field }) => {
  const [editorState, setEditorState] = useState();
  const [show, setShow] = useState(false);
  const onSelect = (data) => {
    editorState?.model.change(() => {
      const imgTag = `<img  src="${data[0]?.download_url}" alt="${data[0]?.basename}"></img>`;
      const viewFragment = editorState.data.processor.toView(imgTag);
      const modelFragment = editorState.data.toModel(viewFragment);
      editorState.model.insertContent(modelFragment);
    });
    setEditorState(null);
    setShow(false);
  };
  const handleClose = () => {
    setShow(false);
  };
  return /* @__PURE__ */ React.createElement("div", { key: field.key, className: "position-relative" }, /* @__PURE__ */ React.createElement(
    "p",
    {
      onClick: () => setShow(true),
      className: `${styles_module_default["image-upload-button"]} position-absolute bottom-0 end-0 zindex-1 mb-0 cursor-pointer`
    },
    /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/data-stream.svg", className: "bg-dark" })
  ), /* @__PURE__ */ React.createElement(
    ModalDAMComponent,
    {
      show,
      onHide: handleClose,
      onSelect,
      type: "image",
      accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"]
      }
    }
  ), /* @__PURE__ */ React.createElement("div", { className: `${styles_module_default["custom-editor"]} haha` }, /* @__PURE__ */ React.createElement(
    CKEditor,
    {
      editor: ClassicEditor,
      config: {
        removePlugins: [
          "CKFinderUploadAdapter",
          "CKFinder",
          "EasyImage",
          "Image",
          "ImageCaption",
          "ImageStyle",
          "ImageToolbar",
          "ImageUpload",
          "MediaEmbed"
        ]
      },
      data: field?.getValueSelected ?? "",
      onReady: async (editor) => {
        setEditorState(editor);
        editor.editing.view.change((writer) => {
          writer.setStyle(
            { "max-height": "400px", "min-height": "200px" },
            editor.editing.view.document.getRoot()
          );
        });
      },
      onChange: (_event, editor) => {
        const data = editor.getData();
        field.handleChange(data);
      }
    }
  )));
};

export {
  FormEditor
};
