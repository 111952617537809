import {
  Instance_default
} from "./chunk-ACBX6SY4.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Pim/PimField/PimFieldRoute.ts
var PimFieldRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem-pim_field";
    this.getList = (filter = {}, filterList = {}) => {
      const buildFilter = this.createFilters(filter);
      const buildFilterList = this.createFilterList(filterList);
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          ...buildFilter,
          ...buildFilterList
        })
      );
    };
    this.createFilters = (filters) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object") {
          switch (value.type) {
            case "custom_fields":
              buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
              break;
            case "filter":
              buildFilter["filter[" + key + "]"] = value.value;
              break;
            default:
              break;
          }
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
    this.createFilterList = (filter) => {
      let buildFilterList = {};
      for (const [key, value] of Object.entries(filter)) {
        if (Array.isArray(value)) {
          buildFilterList["list[" + key + "][]"] = value;
        } else {
          buildFilterList["list[" + key + "]"] = value;
        }
      }
      return buildFilterList;
    };
    this.getDetail = (id = 0, filter = {}) => {
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          id,
          ...filter
        })
      );
    };
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option
        }),
        data
      );
    };
    this.update = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: this.option
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.updateStatus = (listSelected) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option,
          task: "bulkUpdate"
        }),
        {
          items: listSelected
        }
      );
    };
    this.delete = (id) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: this.option
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: { id }
        }
      );
    };
    this.deleteFields = (listSelected) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option,
          task: "bulkDelete"
        }),
        {
          items: listSelected
        }
      );
    };
  }
};
var PimFieldRoute_default = PimFieldRoute;

export {
  PimFieldRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
