import {
  Instance_default
} from "./chunk-ACBX6SY4.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Pim/PimTag/PimTagRoute.ts
var PimTagRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem-item_tag_44";
    this.getList = (filters) => {
      const buildFilters = this.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          ...buildFilters
        })
      );
    };
    this.buildFilter = () => {
    };
    this.createFilters = (filters) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object") {
          switch (value.type) {
            case "custom_fields":
              buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
              break;
            case "filter":
              buildFilter["filter[" + key + "]"] = value.value;
              break;
            default:
              break;
          }
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
  }
};
var PimTagRoute_default = PimTagRoute;

export {
  PimTagRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
